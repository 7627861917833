var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"heading d-flex align-center mb-3"},[_c('h3',{staticClass:"title"},[_vm._v(" User Roles ")]),_c('v-spacer'),_c('v-btn',{staticClass:"rounded-0 mr-2 unwidth",attrs:{"small":"","color":"green800","ripple":false},on:{"click":_vm.onAdd}},[_c('fai',{staticClass:"green500--text",attrs:{"icon":['fas','plus']}})],1),_c('v-btn',{staticClass:"rounded-0 unwidth",attrs:{"small":"","color":"blue700","ripple":false},on:{"click":_vm.getRoles}},[_c('fai',{staticClass:"blue500--text",attrs:{"icon":['fas','sync']}})],1)],1),(_vm.isDeleting)?_c('v-alert',{attrs:{"tile":"","color":"primary700 primary100--text"}},[_c('div',{staticClass:"d-flex align-center"},[_c('fai',{staticClass:"text-h5",attrs:{"icon":['fad', 'exclamation-triangle']}}),_c('p',{staticClass:"mb-0 mx-4 text-body-2"},[_vm._v(" Confirm "),_c('b',[_vm._v("deletion")]),_vm._v(" of role "),_c('b',[_vm._v(_vm._s(_vm.isDeleting))]),_vm._v(". This is irreversible and will affect users and other roles attached to it. ")]),_c('v-btn',{staticClass:"rounded-0 mr-2 unwidth",attrs:{"small":"","color":"grey500","ripple":false},on:{"click":function($event){return _vm.onDelete(_vm.isDeleting)}}},[_c('fai',{staticClass:"green500--text",attrs:{"fixed-width":"","icon":['fas','check']}})],1),_c('v-btn',{staticClass:"rounded-0 unwidth",attrs:{"small":"","color":"grey500","ripple":false},on:{"click":function($event){_vm.isDeleting = null}}},[_c('fai',{staticClass:"primary500--text",attrs:{"fixed-width":"","icon":['fas','times']}})],1)],1)]):_vm._e(),_c('v-data-table',{staticClass:"data-table",attrs:{"headers":_vm.headers,"items":_vm.roles,"disable-sort":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.permissions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary500--text"},[_vm._v(" "+_vm._s(item.fullPerms.length)+" perms ")])]}},{key:"item.inherits",fn:function(ref){
var value = ref.value;
return [_c('em',{staticClass:"text--secondary",domProps:{"textContent":_vm._s(value)}})]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('UserAvatar',{staticClass:"mr-2",attrs:{"user":item.creator,"size":"24"}}),_c('Username',{attrs:{"user":item.creator}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('fai',{staticClass:"primary500--text link mr-2",attrs:{"icon":['fad', 'pencil-alt']},on:{"click":function($event){return _vm.onEdit(item)}}}),_c('fai',{class:item.permanent ? 'text--disabled' : 'link primary500--text',attrs:{"icon":['fad', 'trash']},on:{"click":function($event){!item.permanent ? _vm.isDeleting = item.name : null}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }