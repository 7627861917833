<template>
  <div>
    <div class="heading d-flex align-center mb-3">
      <h3 class="title">
        User Roles
      </h3>
      <v-spacer />
      <v-btn
        small
        color="green800" class="rounded-0 mr-2 unwidth"
        :ripple="false" @click="onAdd"
      >
        <fai class="green500--text" :icon="['fas','plus']" />
      </v-btn>
      <v-btn
        small
        color="blue700" class="rounded-0 unwidth"
        :ripple="false" @click="getRoles"
      >
        <fai class="blue500--text" :icon="['fas','sync']" />
      </v-btn>
    </div>

    <v-alert v-if="isDeleting" tile color="primary700 primary100--text">
      <div class="d-flex align-center">
        <fai :icon="['fad', 'exclamation-triangle']" class="text-h5" />
        <p class="mb-0 mx-4 text-body-2">
          Confirm <b>deletion</b> of role <b>{{ isDeleting }}</b>.
          This is irreversible and will affect users and other roles attached to it.
        </p>
        <v-btn
          small
          color="grey500" class="rounded-0 mr-2 unwidth"
          :ripple="false" @click="onDelete(isDeleting)"
        >
          <fai class="green500--text" fixed-width :icon="['fas','check']" />
        </v-btn>
        <v-btn
          small
          color="grey500" class="rounded-0 unwidth"
          :ripple="false" @click="isDeleting = null"
        >
          <fai class="primary500--text" fixed-width :icon="['fas','times']" />
        </v-btn>
      </div>
    </v-alert>

    <v-data-table
      :headers="headers"
      :items="roles"
      disable-sort
      disable-pagination
      hide-default-footer
      class="data-table"
    >
      <template #item.permissions="{ item }">
        <span class="primary500--text">
          {{ item.fullPerms.length }}
          perms
        </span>
      </template>

      <template #item.inherits="{ value }">
        <em class="text--secondary" v-text="value" />
      </template>

      <template #item.createdBy="{ item }">
        <div class="d-flex align-center">
          <UserAvatar :user="item.creator" size="24" class="mr-2" />
          <Username :user="item.creator" />
        </div>
      </template>

      <template #item.actions="{ item }">
        <fai
          :icon="['fad', 'pencil-alt']"
          class="primary500--text link mr-2"
          @click="onEdit(item)"
        />
        <fai
          :icon="['fad', 'trash']"
          :class="item.permanent ? 'text--disabled' : 'link primary500--text'"
          @click="!item.permanent ? isDeleting = item.name : null"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getPermissionsList, getRolePermissions } from '@/utils/constants/permissions'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name', align: 'start' },
        { text: 'Permissions', value: 'permissions' },
        { text: 'Inherits from', value: 'inherits' },
        { text: 'Last updated by', value: 'createdBy' },
        { text: null, value: 'actions', sortable: false },
      ],
      data: [],
      loading: false,
      isDeleting: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
    }),
    roles() {
      const { data } = this

      if (!data) return {}

      const roles = data.reduce((acc, v) => {
        acc[v.name] = {
          permissions: v.permissions,
          inherits: v.inherits,
        }
        return acc
      }, {})

      return data.map(role => {
        const perms = getPermissionsList(role.permissions)
        const rolePermissions = getRolePermissions(role.name, roles)
        const rolePerms = getPermissionsList(rolePermissions)
        const fullPermissions = role.permissions | rolePermissions
        const fullPerms = getPermissionsList(fullPermissions)

        return {
          ...role,
          perms,
          rolePermissions,
          rolePerms,
          fullPermissions,
          fullPerms,
        }
      }).sort((a, b) => b.fullPerms.length - a.fullPerms.length)
    },
  },
  created() {
    this.getRoles()
  },
  methods: {
    async getRoles() {
      this.loading = true

      try {
        const data = await this.$socket.request('admin.roles.list')
        this.data = data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    async onDelete(role) {
      this.loading = true

      try {
        await this.$socket.request('admin.roles.delete', role)
        this.getRoles()
        this.$toast.success(`Role ${role} deleted successfully.`)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.isDeleting = null
        this.loading = false
      }
    },
    async saveRole(role) {
      if (!role) return
      this.loading = true

      const { name, inherits, permissions } = role

      try {
        await this.$socket.request('admin.roles.set', {
          name,
          inherits: inherits ?? '',
          permissions,
        })

        this.$toast.success(`Role ${name} saved successfully.`)
        this.getRoles()
      } catch (error) {
        this.$toast.error(error.message)

        // reopen on error
        this.onEdit({ name, inherits, permissions })
      } finally {
        this.loading = false
      }
    },
    async onEdit(role) {
      const modal = this.$modal.open(
        'admin-role-edit',
        { hasReturnValue: true },
        { roles: this.roles, role },
      )
      const updatedRole = await modal.promise

      if (!updatedRole) return

      this.saveRole(updatedRole)
    },
    async onAdd() {
      const modal = this.$modal.open(
        'admin-role-edit',
        { hasReturnValue: true },
        { roles: this.roles },
      )
      const role = await modal.promise

      if (!role) return

      this.saveRole(role)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-tabs-items {
  background: transparent !important;
}

.data-table ::v-deep {
  background-color: grey(600);

  .v-data-table-header {
    background-color: grey(500);
  }

  .v-data-footer {
    background-color: grey(500);
  }
}

.pagination ::v-deep {
  .v-pagination__item,
  .v-pagination__navigation {
    border-radius: 0;
    background-color: grey(500);
    box-shadow: none;
  }
}
</style>
